import React, { useContext } from "react";
import ReactDOM from "react-dom";
import styled, { ThemeContext } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../styles/Confirmation";

export default function index({
  isVisible = true,
  headerMessage = "Confirmation",
  message = "",
  toggleConfirmationVisible,
  onSubmit,
}) {
  const theme = useContext(ThemeContext);

  const handleSubmit = () => {
    toggleConfirmationVisible(false);
    onSubmit();
  };
  const handleClose = () => {
    toggleConfirmationVisible(false);
  };

  if (!isVisible) {
    return <></>;
  }

  return ReactDOM.createPortal(
    <ConfirmationModal
      data-testid="app-dashboard-profile-confirmation"
      className="modal"
      theme={theme}
      style={{ paddingTop: 0 }}
    >
      <div className="modal-content">
        <div className="close-btn">
          <span
            className="close"
            onClick={() => {
              toggleConfirmationVisible(false);
            }}
          >
            &times;
          </span>
        </div>

        <div className="profile-confirmation">
          {/* <center>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="6x"
              style={{ marginBottom: 20, color: "#F36F21" }}
            />
          </center> */}
          <h4>{headerMessage}</h4>
          <div>{message}</div>
        </div>
        <div style={{ display: "flex", marginTop: 20 }}>
          <button className="cancel-btn" onClick={handleClose}>
            Cancel
          </button>
          <button className="yes-btn" id="Yes" onClick={handleSubmit}>
            Yes
          </button>
        </div>
      </div>
    </ConfirmationModal>,
    document.getElementById("modal")
  );
}
index.propTypes = {
  onSubmit: PropTypes.func,
};
