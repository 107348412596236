export function yearList(endYear) {
  const years = [];
  let startYear = 1980;
  while (startYear <= endYear) {
    years.push(startYear++);
  }
  return years.sort((a, b) => b - a);
}

export function isValidDate(date) {
  return isNaN(date) && !isNaN(Date.parse(date));
}


export function militaryToRegularTime(militaryTime) {
  // Split the military time into hours and minutes
  const [hours, minutes] = militaryTime.split(':').map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  const regularHours = hours % 12 || 12; // Handle 0 as 12 for 12 AM and 12 PM

  // Format the result
  const regularTime = `${regularHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;

  return regularTime;
}


export const getWeeksDates = date => {
  const dates = [];
  const currentDateCopy = new Date(date);
  const startOfWeek = new Date(
    currentDateCopy.setDate(
      currentDateCopy.getDate() - currentDateCopy.getDay()
    )
  );

  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    dates.push(date);
  }

  return dates;
};

export const addtime = (time, hour) => {
  let times = time.split(":");
  times[0] = parseInt(times[0]) + hour;
  times[0] >= 24 ? (times[0] -= 24) : null;
  times[0] < 10 ? (times[0] = "0" + times[0]) : null;
  return times.join(":");
};

export const timeCompare = (time1, time2) => {
  if (time1 && time2) {
    let arrTime1 = time1.split(":");
    let arrTime2 = time2.split(":");

    if (parseInt(arrTime1[0]) > parseInt(arrTime2[0])) return 1;
    if (parseInt(arrTime1[0]) < parseInt(arrTime2[0])) return -1;
  }
  return 0;
};
