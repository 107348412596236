import styled from "styled-components";

export const EventCreateStyled = styled.div`
  width: auto;
  max-width: 1920px;
  margin: auto;
  padding: 0rem 3em 2rem;
  margin: 20px;

  .attendance-container {
    position: relative;
    padding: 1rem;
    margin-top: 12px;
    background-color: #fff;
    box-shadow: 0 0 25px #eae9e9;
    border-radius: 10px;
  }
  .attendance-container .back-btn {
    width: 25px;
    color: black;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    text-decoration: none;
    margin-top: 3px;
    margin-left: 3px;
  }
  .attendance-container .back-btn svg {
    padding-right: 5px;
  }

  .field-input:placeholder-shown + .field-label {
    max-width: calc(100% - 30%) !important;
  }

  .field-label,
  .field-input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  .field-input {
    font-size: 18px;
    border: 0;
    border-bottom: 2px solid #ccc;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    padding: 5px;
    cursor: text;
    line-height: 1.8;

    padding: 5px 0;
    width: 100%;
    display: block;
    text-indent: 5px;
  }
  .form-group .form-control {
    font-size: 18px;
    border: 0;
    border-bottom: 2px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    padding: 10px;
  }

  .field {
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 1em;
  }

  .field-label,
  .field-input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  .field-input {
    font-size: 18px;
    border: 0;
    border-bottom: 2px solid #ccc;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    padding: 5px;
    cursor: text;
    line-height: 1.8;

    padding: 5px 0;
    width: 100%;
    display: block;
    text-indent: 5px;
    margin-top: 8px;
    margin-bottom: -5px;
  }

  .field-label {
    font-size: 14px;
    color: #4b525a;
  }

  .field-input:placeholder-shown + .field-label {
    overflow: hidden;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.4);
  }

  .field-input::placeholder {
    opacity: 0;
    transition: inherit;
    font-size: 12px;
  }

  .field-input:focus::placeholder {
    opacity: 1;
  }

  .field-input:focus + .field-label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    // margin-bottom: 5px;
    font-weight: bold;
  }

  .react-datetimerange-picker,
  .react-date-picker,
  .react-time-picker {
    width: 100%;
  }
  .react-datetimerange-picker button,
  .react-date-picker button,
  .react-time-picker button {
    width: inherit;
    color: initial;
    background-color: initial;
    box-shadow: initial;
    border-radius: initial;
  }
  .react-datetimerange-picker,
  .react-date-picker,
  .react-time-picker {
    border: none;
    width: 100%;
    margin: 1em 0 1em 0;
  }
  .react-datetimerange-picker input,
  .react-date-picker input,
  .react-time-picker input {
    margin: 0;
    width: initial;
    border-bottom: none;
  }
  .react-datetimerange-picker__wrapper,
  .react-date-picker__wrapper,
  .react-time-picker__wrapper {
    border: none;
  }

  .react-calendar .react-calendar__tile--active,
  .react-calendar__tile--rangeStart {
    background-color: #f26e21 !important;
    color: white !important;
  }

  .react-datetimerange-picker__inputGroup__input--hasLeadingZero,
  .react-date-picker__inputGroup__input--hasLeadingZero,
  .react-time-picker__inputGroup__input--hasLeadingZero {
    padding: 0;
  }
  .react-date-picker__inputGroup__input,
  .react-time-picker__inputGroup__input,
  .react-datetimerange-picker__inputGroup__input {
    display: inline !important;
    transition: none !important;
  }

  .react-calendar__tile--active:enabled:focus {
    background-color: #f26e21;
    color: white;
  }

  .react-datepicker-wrapper {
    margin: 0;
  }
  .react-datepicker__input-container .field {
    margin: 0 !important;
    padding: 0 !important;
  }
  .react-datepicker__input-container .field svg.calendar-icon {
    position: absolute;
    right: 0;
    bottom: 10px;
    color: grey;
  }
  .required {
    color: red;
  }

  .description {
    background: #80808033;
  }
  .description .field {
    margin-bottom: 4px;
    padding: 8px 8px 12px;
  }
  .description .field > input {
    background: transparent;
  }

  .search-field-container {
    display: flex;
    align-items: baseline;
  }
  .search-field-container .field {
    flex: 1;
    position: relative;
    max-width: 350px;
    margin-right: auto;
  }
  .search-field-container .field svg.search-icon {
    position: absolute;
    bottom: 20px;
    right: 15px;
    color: gray;
  }

  .search-field-container .select-field-wrapper {
    position: relative;
  }
  .search-field-container .select-field-wrapper:after {
    content: "\f078";
    position: absolute;
    right: 0;
    bottom: 18px;
    font-size: 10px;
    color: #555;
    font-family: "fontawesome";
  }
  .search-field-container .select-field-wrapper label {
    position: absolute;
    top: -10px;
    color: grey;
    font-size: 12px;
  }
  .search-field-container .select-field-wrapper select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  }

  .viewType {
    margin-left: 1rem;
    display: flex;
    align-items: stretch;
  }
  .viewType > svg {
    height: auto;
    color: grey;
    font-size: 18px;
    cursor: pointer;
    padding: 11px !important;
    border-radius: 0 !important;
    border: 1.65px solid #ccc;
  }
  .viewType > svg:first-child {
    border-right: 0;
  }
  .viewType > svg:last-child {
    border-left: 0;
  }
  .viewType > svg.selected-view {
    color: #fff;
    background: #f5812f;
    border-color: rgb(204 204 204 / 30%);
    transition: 0.15s ease-in-out;
  }
  .viewType > svg:not(.selected-view):hover {
    color: #f5812f;
  }

  .btn-save-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .btn-save {
    background-color: #fc9a3b;
    color: #3c3c3c;
    border: none;
    border-radius: 35px;
    font-weight: bold;
    margin-right: 12px;
    width: 140px;
    margin-top: 8;
    margin-bottom: 8px;
  }
  .circle-icon {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 0 auto;
  }
  .gridView .block .extra_activitybox {
    text-align: center;
  }

  .gridView {
    margin-top: 3rem;
    display: grid;
    grid-gap: 18px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .gridView .block {
    width: auto;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px #ccc;
    border-bottom: 4px solid #f5812f;
  }

  .gridView .block .extra_activitybox .img-container {
    padding: 1rem;
  }
  .gridView .block .extra_activitybox .img-container img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    box-shadow: 0 0 5px #716464;
  }
  .gridView .block .extra_activitybox .attendance-name {
    padding-bottom: 1rem;
  }
  .gridView .block .extra_activitybox .attendance-name a {
    color: #000;
    font-weight: 600;
    text-decoration: none;
    transition: 0.15s ease-in-out;
  }
  .gridView .block .extra_activitybox .attendance-name a:hover {
    color: #f5812f;
  }
  .gridView .block .extra_activitybox .attendance-action {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  .gridView .block .extra_activitybox .attendance-action > div {
    flex: 1;
    padding: 1rem 0;
  }
  .gridView
    .block
    .extra_activitybox
    .attendance-action
    > div:not(:last-child) {
    border-right: 1px solid #ddd;
  }
  .gridView .block .extra_activitybox .attendance-hours {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
  }
  .gridView .block .extra_activitybox .attendance-hours .field {
    padding: 0 0.5rem;
  }
  .gridView .block .extra_activitybox .attendance-hours .field > input {
    text-align: center;
  }
  .gridView .block .extra_activitybox .attendance-hours .field > label {
    font-size: 12px;
  }

  .gridView .block .extra_activitybox .attendance-invitation {
    padding: 1rem;
    color: gray;
    font-size: 14px;
  }
  .gridView .block .extra_activitybox .attendance-invitation span {
    color: #000;
    font-weight: 600;
  }

  // media query
  @media (max-width: 840px) {
    padding: 0rem 1rem 2rem;
  }

  #listView {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 0;
  }

  #listView td,
  #listView th {
    border: 0;
    padding: 15px;
    width: 200px;
  }

  #listView th:not(:last-child) {
    border-right: 1px solid rgb(255 255 255 / 65%);
  }

  #listView tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  #listView th {
    color: white;
    text-align: center;
    white-space: nowrap;
    background-color: #f26e21;
  }

  #listView td .name,
  #listView td .class {
    min-width: 100px;
  }

  #listView a {
    color: #3e89fe;
    text-decoration: none;
  }

  .attendance-status-container,
  .attendance-hours-container {
    min-width: 300px;
    display: flex;
  }
  .attendance-status-container > div {
    width: 100%;
    min-width: 100px;
  }

  .attendance-hours-container > div {
    width: 50%;
  }
  .listViewTableContainer {
    overflow-x: auto;
    margin-top: 3rem;
    // box-shadow: 0px 0px 10px #ccc;
  }
  .listViewTableContainer::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .listViewTableContainer::-webkit-scrollbar-track {
    border-radius: 10px;
    transition: 0.5s ease-in-out;
    background: rgb(243 110 34 / 20%);
  }
  .listViewTableContainer::-webkit-scrollbar-thumb {
    background: #f36e22;
    border-radius: 10px;
  }

  .exclude-icon {
    position: relative;
    background-color: black;
    width: 1.5px;
    height: 14px;
    z-index: 200;
    top: 0px;
    left: -9px;
    transform: rotateY(0deg) rotate(45deg);
  }

  .react-datepicker--time-only {
    width: 200px !important;
  }
  .react-datepicker__time-container {
    width: 100% !important;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100% !important;
  }

  .attendee-header-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 12px;
  }
  .attendee-header-action > div > select {
    border: 1.8px solid #cacacb;
    border-radius: 8px;
    width: 90px;
    height: 37px;
  }

  .attendee-header-action > div > button {
    background-color: white;
    border: 1.8px solid #cacacb;
    border-radius: 8px;
    margin-left: 12px;
    width: 120px;
  }

  .attendance-header-action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15;
    padding-bottom: 15;
  }

  .event-input {
    padding: 8px 16px;
    align-items: center;
    border: 1px solid #cacacb;
    border-radius: 8px !important;
  }
`;

export const EventCreateInputStyled = styled.div`
    display: flex;
    flex-direction: column;
    background-color #F4F4F5;
    color: #6c6c6c;
    padding: 15px;
    border-radius: 10px;


    .event-type-container {
        display: flex;
        align-items: flex-start;
        gap: 24px;
    }

    .event-type-container > div:nth-child(2),  .event-type-container > div:nth-child(3)  {
        margin-left: 12px;
    }

    input[type=radio] {
        width: 17px;
        height: 17px;
        display: inline-block;
        background: #FFFFFF;
        border: 1px solid rgba(220,220,225,1);
        accent-color: #1B0B03;
    }

    
    input[type=checkbox] {
        width: 17px;
        height: 17px;
        display: inline-block;
        background: #FFFFFF;
        border: 1px solid rgba(220,220,225,1);
        accent-color: #1B0B03;

    }


    .event-checkbox-container {
        position: relative;
        top: 8px;
        display: flex;
        align-items: center;
    }
    
    .event-input {
        padding: 8px 16px;
        align-items: center;
        border: 1px solid #cacacb;
        border-radius: 8px !important;
    }

    .event-select {
        height: 38px;
    }

    .event-input-textarea {
        resize: none;
        width: 97%;
    }

    .event-input-container {
        margin-top: 12px;
        margin-bottom: 12px;
    }

     .event-group-container{
        display: grid;
        grid-template-columns: 20% 40% 40%;
        width: 100%;
        grid-gap: 8px;
     }

     .event-group-container > div {
        width: 100%;
     }

     .event-group-container > div > select {
        width: 97%;
     }
      

      .event-details-container {
        display: grid;
        grid-template-columns: 50% 23% 23%;
        width: 100%;
        grid-gap: 8px;
      }

      .event-date-container {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      
      .event-date-container > div:not(:first-child) {
        margin-left: 5px;
      }
      .event-details-container > div  {
        width: 100%;
      }

      .event-label {
        height: 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #6C6C6C;
      }

      .event-type-label {
        position: relative;
        top: -3px;
        color: #1B0B03;
        font-size: 16px;
        font-weight: 400;
      }

      .event-details-container > div > input, .event-details-container > div > select {
        width: 100%;
        position: relative;
        box-sizing: border-box;
      }

      .event-date-picker {
        width: 150px;
        height:20px;
      }

      .event-time-picker {
        width: 80px !important;
        height: 20px;
      }
      
      .event-time-picker {
        width: 100px;
        height:20px;
      }

      
      .input-item {
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 10px;
      }
    
      .input-item img {
        position: absolute;
        right: 20px;
        bottom: 20px;
        color: #6c6c6c;
      }
    
      .date-filter-container {
        display: flex;
        gap: 16px;
      }

      @media (max-width: 768px) {
        .event-details-container {
          grid-template-columns: 1fr;
        }
        .date-filter-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .event-group-container {
            grid-template-columns: 1fr;
        }

        .event-date-container {
            display: flex;
            flex-direction: column;
        }
      }

`;

export const EventAttendeeListStyled = styled.div`
     padding: 15px;
    background-color: white;
    
    .tooltip-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;    
    }

    #event-attendee-table {
        width: 100%;
        border-collapse: collapse;
    }

    .event-attendee-header {
        background-color: #FC9A3B;
        color: #1B0B03;
        height 40px;
        padding: 12px !important;
        padding: 15px;
    }

      
    .event-attendee-header th:nth-child(1) {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        text-align: left;
    }
      
    .event-attendee-header th:nth-child(6) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    #event-attendee-table tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

      
    #event-attendee-table tr:nth-child(even){
        background-color: #f4f4f5;
    }
 
    #event-attendee-table tr:nth-child(odd) {
        background-color: white;
    }


    #event-attendee-table tr td:not(:first-child) {
        text-align: center;
    }

    #event-attendee-table td,
    #event-attendee-table th {
        border: 0;
        padding: 15px;
    }

    #event-attendee-table th {
        padding-left: 15px;
    }

    .attendance-icon {
        width: 30px;
        height: 30px;
        border: 1px solid #6C6C6C;
        background-color: #f4f4f5;
        margin: 0 auto;
        border-radius: 50%;
        padding-top: 1.5px;
    }

    .event-input {
        padding: 8px 16px;
        width: 150px;
        align-items: center;
        border: 1px solid #cacacb;
        border-radius: 8px !important;
    }

    .attendance-input-hours {
        padding: 8px 16px;
        width: 25px;
        align-items: center;
        text-align: center;
        border: 1px solid #cacacb;
        height: 14.4px;
    }

    .attendance-input-btn {
        position: relative;
        top: 2px;
        background-color: white;
        border: 1px solid #cacacb;
        margin-top: 5px;
        height: 32.8px;
    }
    .attendance-input-dec {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: 0px;
    }

    .attendance-input-inc {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: 0px;
    }

    .attendance-present { 
        background-color: #F0F9EE;
        border: 1px solid #3B7D2A;
        color: #3B7D2A;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-late { 
        background-color: #FFE3CF;
        border: 1px solid #EF8722;
        color: #EF8722;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-absent { 
        background-color: #FDE6E6;
        border: 1px solid #EB0000;
        color: #EB0000;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .attendance-present > svg,
    .attendance-late > svg,
    .attendance-absent > svg {
        margin: 0 auto;
    }
    
    .attendance-excused { 
        font-size: 12px;
        height: 15px;
        margin: 0 auto;
        color: #6C6C6C;
    }

    .attendance-present-container {
        position: relative;
        top: 0px;
    }
    
    
    .link-text {
        color: #18A0FB !important;
        text-decoration: none;
    }

    .empty-name {
        width: 70px;
        text-align: center;
    }

    .btn-filter { 
        background-color: white;
        border: 1.8px solid #CACACB;
        border-radius: 8px;
        margin-left: 12px;
        width: 120px;

    }
 
    .attendee-header-action {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 12px;
      margin-top: 12px;
      margin-bottom: 30px;
	  }
	.attendee-header-action > div > select {
		border: 1.8px solid #CACACB;
		border-radius: 8px;
		width: 90px;
		height: 40px;
	}

	.attendee-header-action > div > button {
		background-color: white;
		border: 1.8px solid #CACACB;
		border-radius: 8px;
		margin-left: 12px;
		width: 120px;
	}
	.btn-view-toggle-container button {
		background-color: white;
		border: 1px solid #dedede;
	}

	.btn-view-toggle-container button:nth-child(1)  {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
   }
  
   .btn-view-toggle-container button:nth-child(2)  {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

    .view-toggle-selected {
		background-color: #FC9A3B !important;
	}

    
    .user-details {
        text-align: left;
        position: relative;
        top: 8px;
        left: 12px;
    }

    .user-id { 
        color: #6C6C6C;
    }

    
    .input-pagination {
        padding: 8px 16px;
        align-items: center;
        border: 1.8px solid #dedede;
        border-radius: 8px !important;
    }
  
    .btn-view-page-container {
        background-color: white;
        border: 2px solid #dedede;
        border-radius: 12px;
        overflow: hidden;
      
      }
      
    .btn-view-page-container button {
        background-color: white !important;
    }

    .event-view-input {
        margin-left: 4px;
    }

    #import-button {
      position: relative;
      top: 0;
      background-color: white;
      border: 1.8px solid #CACACB;
      border-radius: 8px;
      margin-left: -10px !important;
      width: 120px;
      padding: 10px;
    }

    #import-button {
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      color: #1B0B03 !important;
    }

    .filter-input-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 12px;
      margin-bottom: 12px;
    }

     .filter-input-container > input {
        margin-right: 3px;
        width:80px;
    }

    .input-filter {
      padding: 8px 16px;
      align-items: center;
      border: 1.8px solid #dedede;
      border-radius: 8px !important;
    }

    .input-search-full {
      width: 100% !important;
      height: 22px !important;
      padding: 8px 16px;
      align-items: center;
    }

    .input-icons {
      // width:150px;
      position: relative;
      display: flex;
      align-items: center;
  }

    .input-search {
      width: 16px !important;
      height: 22px !important;
      padding: 8px 16px;
      align-items: center;
    }

    .tooltip-card {
      display: flex;
      width: 208px;
      height: 355px;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
    }

    .tooltip-text {
      font-size: 18px;
      font-weight: 600;
      color: #000;
      margin-top: 0;
      margin-bottom: 0;
    }

    .tooltip-sub {
      font-size: 12px;
      font-weight: 400;
      color: #000;
      margin-top: 0;
      margin-bottom: 0;
    }


`;
