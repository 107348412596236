import React, { useEffect, useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSearch,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Location, useNavigate, useLocation } from "@reach/router";
import Popover, { ArrowContainer } from "react-tiny-popover";
import { parse } from "query-string";
import Settings from "../images/settings.svg";
import Bell from "../images/bell.svg";

import Logo from "../images/logo1.png";
import LotLogo from "../images/lot.jpg";
import { requestLogout } from "../redux/actions/Auth";

import { s3BucketRootPath } from "../constants/aws";
import { IoMenu } from "react-icons/io5";

import LoginBox from "./LoginBox";

const HeaderStyled = styled.header`
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize};
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  margin: 0;
  padding: 10px 64px;
  height: 64px;
  div {
    display: inline;
  }
  a {
    display: block;
    color: grey;
    text-decoration: none;
    cursor: pointer;
  }
  #app-header-right,
  #app-header-left {
    position: relative;
    display: flex;
    align-items: center !important;
    gap: 24px !important;
  }
  #app-header-left {
    margin-bottom: 18px;
  }
  #app-header-left .profile {
    position: relative;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    // top: -10px;
  }
  #app-header-left a {
    font-size: 16px;
    font-weight: 400;
    color: #6c6c6c;
  }
  #app-header-left a.selected > span {
    // display: inline-block;
    border-bottom: 5px solid #3c3c3c;
    bottom: 0;
  }
  #dashboard-setting > img {
    width: 24px !important;
    height: 24px !important;
    color: #6c6c6c;
  }
  .logo-image {
    width: 145px;
    height: 40px;
    object-fit: contain;
    margin-top: 7px;
  }
  .input-icons {
    position: relative;
    display: flex;
    align-items: center;
  }
  .input-search {
    width: 230px !important;
    height: 20px;
    padding: 8px 16px;
    align-items: center;
    border: 1px solid #dedede;
    border-radius: 100px;
  }
  .icon {
    width: 16px !important;
    height: 16px !important;
    position: absolute;
    left: 197px !important;
    // transform: translate(300%);
    color: #6c6c6c !important;
  }
  #dashboard-setting {
    display: flex;
    justify-content: space-around;
    margin-top: 1em;
  }

  .vendor-welcome-message {
    width: 100%;
    position: absolute;
    display: flex;
    margin: 0;
    align-items: center;
    height: 100%;
    left: 250px;
    font-weight: 100;
    white-space: pre;
  }

  .vendor-welcome-message .name {
    color: #f47b2c;
    font-weight: 400;
  }

  .header-login {
    display: flex;
    align-items: center;
    color: #f47b2c;
    font-weight: 400;
    cursor: pointer;
  }

  .menu-mobile {
    display: none;
  }

  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    // width: 100% !important;
    padding: 10px 13px;

    // Mobile
    #app-header-left-mb {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      gap: 24px !important;
    }

    #app-header-left {
      display: none;
      flex-direction: row;
      background-color: #fff !important;
    }

    .vendor-welcome-message {
      justify-content: center;
      position: relative;
      margin-top: 0.5rem;
      left: 0;
    }

    .input-icons {
      display: none;
    }

    .menu-mobile {
      display: flex;
      width: 40px;
      height: 40px;
    }
  }

  @media (min-width: 600px) {
    grid-template-columns: 30% 70%;
    text-align: left;
    #app-header-left {
      display: flex;
      text-align: center;
      justify-content: flex-end;
    }
    #app-header-right {
      grid-template-columns: 50% 50%;
    }
    #app-header-left a {
      position: relative;
      top: 10px;
      margin: 0 0.5em 0 0.5em;
    }
    #app-header-left a.selected > span {
      padding-bottom: 1.8em;
    }
    .input-icons {
      margin-top: 5px;
    }
    #dashboard-setting {
      display: flex;
      align-items: center;
      margin-top: 0;
      gap: 16px;
      justify-content: space-between;
    }
    #dashboard-setting > img {
      width: 24px !important;
      height: 24px !important;
      color: #6c6c6c;
    }
    .icon-dropdown {
      color: #1b0b03 !important;
      padding-left: 10px !important;
    }
    #dashboard-setting a {
      margin: 0 5px 0 5px;
    }
  }
  @media (min-width: 1500px) {
    1grid-template-columns: 20% 80%;
  }
`;
const LayoutStyled = styled.div`
  margin: 0;
  padding: 0;

  .react-tiny-popover-container {
    overflow: auto !important;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }
`;

const MobileMenu = styled.div`
  #app-header-left-mb {
    position: relative;
    display: none;
    align-items: center !important;
    gap: 24px !important;
    background: #fff !important;
    width: 100% !important;
    padding: 16px;
    transition: all 0.3s ease;
  }
  #app-header-left-mb {
    margin-bottom: 18px;
  }
  #app-header-left-mb .profile {
    position: relative;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    // top: -10px;
  }
  #app-header-left-mb a {
    font-size: 16px;
    font-weight: 400;
    color: #6c6c6c;
    text-decoration: none;
  }
  #app-header-left-mb a.selected > span {
    // display: inline-block;
    border-bottom: 5px solid #3c3c3c;
    bottom: 0;
  }

  .dash-setting-mb {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  }

  .setting-mb {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }

  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    // width: 100% !important;
    padding: 10px 13px;

    // Mobile
    #app-header-left-mb {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      gap: 24px !important;
    }

    #app-header-left {
      display: none;
      flex-direction: row;
      background-color: #fff !important;
    }

    .vendor-welcome-message {
      justify-content: center;
      position: relative;
      margin-top: 0.5rem;
      left: 0;
    }

    .input-icons {
      display: none;
    }

    .menu-mobile {
      display: flex;
      width: 40px;
      height: 40px;
    }
  }
`;

const PopoverStyled = styled.div`
  background: white;
  border: 1px solid lightgrey;
  a {
    display: block;
    color: grey;
    text-decoration: none;
    cursor: pointer;
    margin: 1em;
  }
  svg {
    margin-right: 1em;
  }
`;

const DefaultLogo = () => (
  <img
    className="logo-image"
    data-testid="app-logo"
    src={Logo}
    alt="Bcombs Logo"
  />
);
const DefaultLotLogo = () => (
  <img
    className="logo-image"
    data-testid="app-logo"
    src={LotLogo}
    alt="Bcombs Lot Logo"
    // style={{ width: 180, height: "auto" }}
  />
);

export default function Layout({ children }) {
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);
  const [isPopOverVisibleMb, setIsPopOverVisibleMb] = useState(false);
  const [isPopOverGradeVisibleMb, setIsPopOverGradeVisibleMb] = useState(false);
  const [isPopOverSettingsVisible, setIsPopOverSettingsVisible] =
    useState(false);
  const [isPopOverSettingsVisibleMb, setIsPopOverSettingsVisibleMb] =
    useState(false);
  const [isPopOverDataVisible, setIsPopOverDataVisible] = useState(false);
  const [isPopOverDataVisibleMb, setIsPopOverDataVisibleMb] = useState(false);
  const [currentUserProfilePhoto, setCurrentUserProfilePhoto] = useState(false);
  const [currentUserType, setCurrentUserType] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [currentVendor, setCurrentVendor] = useState(null);

  const { auth, status, userTypes, vendors, applications } = useSelector(
    ({ auth, status, userTypes, vendors, applications }) => {
      return { auth, status, userTypes, vendors, applications };
    }
  );

  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      setCurrentUserProfilePhoto(auth.profile_img || auth.picture);

      if (Array.isArray(vendors)) {
        const vendor = vendors.find((item) => item.is_default);
        setCurrentVendor(vendor);
      }

      if (Object.keys(userTypes).length > 0) {
        const ut = userTypes.filter((type) => {
          return type.id === auth.type;
        })[0];

        setCurrentUserType(ut && ut.name ? ut.name : "");
      }
    }
  }, [auth, vendors]);

  // useEffect(() => {
  //   if(currentUserType === "USER" ) {
  //     setuserApplications(applications.userAllApplications);
  //   }

  // }, [applications.userAllApplications]);

  useEffect(() => {
    setTimeout(() => {
      setShowLoginBox(true);
    }, 5000);
  }, []);

  // const location = useLocation();
  // const queryParams = parse(location.search);
  const urlParams = new URLSearchParams(window.location.search);
  const vendorId = urlParams.get("vendor");
  const formId = urlParams.get("form");

  const [showLoginBox, setShowLoginBox] = useState(false);

  const handleLoginBoxClose = () => {
    setShowLoginBox(false);
  };

  const applicationUrl = location.origin + "/application/";
  const customFormUrl = location.origin + "/form/";
  const isLotUrl = location.href.includes("/lot");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (Array.isArray(vendors)) {
      let defaultVendor = vendors?.find((item) => item.is_default);
      // Use defaultVendor as needed
      defaultVendor = defaultVendor ? defaultVendor : vendors[0];

      if (defaultVendor) {
        setSelectedVendor(defaultVendor);
      }
    }
  }, [vendors]);

  return location.pathname === "/" ? (
    children
  ) : (
    <LayoutStyled data-testid="app-layout" theme={theme}>
      <HeaderStyled data-testid="app-title" theme={theme.header}>
        <div id="app-header-right">
          <Link to={`/dashboard/application${window.location.search}`}>
            {(location.href.includes(applicationUrl) ||
              location.href.includes(customFormUrl)) &&
            !isLotUrl ? (
              vendors && vendors.length > 0 && vendors[0]?.logo ? (
                <img
                  src={`${s3BucketRootPath}/${vendors[0]?.logo}`}
                  style={{ width: 180, height: "auto" }}
                />
              ) : (
                <DefaultLogo />
              )
            ) : isLotUrl ? (
              <DefaultLotLogo />
            ) : (
              <DefaultLogo />
            )}
          </Link>
          {location.href.includes(applicationUrl) &&
            vendors &&
            vendors.length > 0 && (
              <div>
                <span className="vendor-welcome-message">
                  Welcome to &nbsp;
                  <span className="name">{vendors[0].name}</span>
                </span>
              </div>
            )}
          <Location
            children={(context) => {
              if (
                context.location.pathname.includes("dashboard") &&
                auth.status === "SIGNED_IN"
              ) {
                return (
                  <>
                    <div className="input-icons">
                      <input
                        type="text"
                        placeholder="Search"
                        className="input-search"
                      />
                      <FontAwesomeIcon className="icon" icon={faSearch} />
                    </div>
                  </>
                );
              }
            }}
          />
        </div>
        {/* Mobile */}
        <IoMenu className="menu-mobile" onClick={toggleMenu} />
        {/* Desktop */}
        <div id="app-header-left">
          {location.href.includes(applicationUrl) ||
          location.href.includes(customFormUrl) ? (
            auth.status == "SIGNED_IN" ? (
              ""
            ) : (
              // <div className="header-login"
              //   onClick={() => {setShowLoginBox(true)}}
              // >
              //   Login
              // </div>
              <div />
            )
          ) : (
            ""
          )}
          <Location
            children={(context) => {
              if (
                context.location.pathname === "/auth/forgot-password" ||
                context.location.pathname === "/auth/create"
              ) {
                return (
                  <>
                    <Link data-testid="app-header-login" to="/login">
                      Login
                    </Link>
                    {/* <Link data-testid="app-header-register" to="/auth/create">
                      Register
                    </Link> */}
                  </>
                );
              }
            }}
          />
          <Location
            children={(context) => {
              if (
                context.location.pathname.includes("dashboard") &&
                auth.status === "SIGNED_IN"
              ) {
                return (
                  <>
                    {currentUserType === "VENDOR" && (
                      <>
                        <Link
                          className={`${
                            context.location.pathname ===
                            "/dashboard/application"
                              ? "selected"
                              : ""
                          }`}
                          to={`/dashboard/application${window.location.search}`}
                        >
                          <span>Application</span>
                        </Link>
                        <Link
                          className={`${
                            context.location.pathname === "/dashboard/newgroups"
                              ? "selected"
                              : ""
                          }`}
                          to={`/dashboard/newgroups${window.location.search}`}
                        >
                          <span>Groups</span>
                        </Link>
                      </>
                    )}
                    {
                      // Temporary Fix
                      // Trello card https://trello.com/c/BXEQaXbB/252-for-users-only-can-we-only-show-the-application-page-and-menu-hide-dashboard-calendar-events-and-contacts-pages-and-menu-items
                      currentUserType === "VENDOR" && (
                        <>
                          {/* <Link
                          className={`${
                            context.location.pathname === "/dashboard"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard"
                          state={{ calendarName: "" }}>
                          <span> Dashboard</span>
                        </Link> */}
                          {/* <Link
                          className={`${
                            context.location.pathname ===
                            "/dashboard/mycalendars"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/mycalendars">
                          <span>Calendars</span>
                        </Link> */}
                          {/* <Link
                          className={`${
                            context.location.pathname === "/dashboard/myevents"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/myevents">
                          <span>Events</span>
                        </Link> */}
                          {/* <Link
                            className={`${
                              context.location.pathname ===
                              "/dashboard/bcdisplaycalendar"
                                ? "selected"
                                : ""
                            }`}
                            to={`/dashboard/bcdisplaycalendar${window.location.search}`}
                          >
                            <span>Calendar</span>
                          </Link> */}
                          {/* <Link
                            className={`${
                              context.location.pathname ===
                              "/dashboard/studentdata"
                                ? "selected"
                                : ""
                            }`}
                            to={`/dashboard/studentdata${window.location.search}`}
                          >
                            <span>Data</span>
                          </Link> */}
                          <Popover
                            containerStyle={{
                              position: "relative",
                              right: 25,
                            }}
                            isOpen={isPopOverDataVisible}
                            position={["bottom", "right"]}
                            content={({
                              position,
                              targetRect,
                              popoverRect,
                            }) => (
                              <ArrowContainer
                                position={position}
                                targetRect={targetRect}
                                align="end"
                                popoverRect={popoverRect}
                                arrowColor="lightgrey"
                                arrowSize={7}
                                arrowStyle={{ opacity: 1 }}
                                containerStyle={{
                                  right: 12,
                                }}
                                arrow="center"
                              >
                                <PopoverStyled>
                                  {/* <Link
                                    to={`/dashboard/studentdata${window.location.search}`}
                                    onClick={() => {
                                      setIsPopOverDataVisible(false);
                                    }}
                                  >
                                    <span>Attendance</span>
                                  </Link> */}
                                  <Link
                                    to={`/dashboard/attendance/newevents?vendorId=${selectedVendor?.id2}&attendanceType=mentoring&name=all`}
                                    onClick={() => {
                                      setIsPopOverDataVisible(false);
                                    }}
                                  >
                                    <span>Attendance Summary</span>
                                  </Link>
                                  {/* <Link
                                    to="/dashboard/attendance/events"
                                    onClick={() => {
                                      setIsPopOverDataVisible(false);
                                    }}
                                  >
                                    <span>Existing Events</span>
                                  </Link> */}

                                  <Link
                                    // to={`/dashboard/attendance/${selectedVendor?.id2}/all?vendorId=${selectedVendor?.id}`}
                                    to={`/dashboard/attendance/newattendance/create?vendorId=${selectedVendor?.id2}&attendanceType=mentoring&name=all`}
                                    onClick={() => {
                                      setIsPopOverDataVisible(false);
                                    }}
                                  >
                                    <span>Attendance Input</span>
                                  </Link>

                                  <Link
                                    to={`/dashboard/attendance/view/${selectedVendor?.id2}?type=all`}
                                    onClick={() => {
                                      setIsPopOverDataVisible(false);
                                    }}
                                  >
                                    <span>Attendance View</span>
                                  </Link>

                                  {/* <Link
                                    to="/dashboard/newcalendar"
                                    onClick={() => {
                                      setIsPopOverDataVisible(false);
                                    }}
                                  >
                                    <span>New Calendar</span>
                                  </Link> */}
                                </PopoverStyled>
                              </ArrowContainer>
                            )}
                            onClickOutside={({
                              position,
                              targetRect,
                              popoverRect,
                            }) => {
                              setIsPopOverDataVisible(false);
                            }}
                          >
                            <a
                              onClick={() => {
                                setIsPopOverDataVisible(true);
                              }}
                            >
                              {/* <FontAwesomeIcon icon={faCog} className="icon-item" /> */}
                              <span>
                                Data{" "}
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className="icon-item icon-dropdown"
                                />
                              </span>
                            </a>
                          </Popover>
                          {/* <Link
                            className={`${
                              context.location.pathname === "/dashboard/grades"
                                ? "selected"
                                : ""
                            }`}
                            to={`/dashboard/grades${window.location.search}`}
                          >
                            <span>Grades</span>
                          </Link> */}
                          <Popover
                            containerStyle={{
                              position: "relative",
                              right: 25,
                            }}
                            isOpen={isPopOverGradeVisibleMb}
                            position={["bottom", "right"]}
                            content={({
                              position,
                              targetRect,
                              popoverRect,
                            }) => (
                              <ArrowContainer
                                position={position}
                                targetRect={targetRect}
                                align="end"
                                popoverRect={popoverRect}
                                arrowColor="lightgrey"
                                arrowSize={7}
                                arrowStyle={{ opacity: 1 }}
                                containerStyle={{
                                  right: 12,
                                }}
                                arrow="center"
                              >
                                <PopoverStyled>
                                  <Link
                                    to={`/dashboard/grades/input?type=all&group_type=${
                                      selectedVendor?.default_form
                                        ? "forms"
                                        : "bcombs"
                                    }&vendor=${selectedVendor?.id2}&group_id=${
                                      selectedVendor?.id
                                    }&request_type=vendor`}
                                    onClick={() => {
                                      setIsPopOverGradeVisibleMb(false);
                                    }}
                                  >
                                    <span>Input</span>
                                  </Link>

                                  <Link
                                    to={`/dashboard/grades?type=all&group_type=${
                                      selectedVendor?.default_form
                                        ? "forms"
                                        : "bcombs"
                                    }&vendor=${
                                      selectedVendor?.id2
                                    }${`&group_id=${selectedVendor?.id}`}&request_type=vendor`}
                                    onClick={() => {
                                      setIsPopOverGradeVisibleMb(false);
                                    }}
                                  >
                                    <span>View</span>
                                  </Link>
                                </PopoverStyled>
                              </ArrowContainer>
                            )}
                            onClickOutside={() => {
                              setIsPopOverGradeVisibleMb(false);
                            }}
                          >
                            <a
                              onClick={() => {
                                setIsPopOverGradeVisibleMb(true);
                              }}
                            >
                              {/* <FontAwesomeIcon icon={faCog} className="icon-item" /> */}
                              <span>
                                Grades{" "}
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className="icon-item icon-dropdown"
                                />
                              </span>
                            </a>
                          </Popover>
                          {/* <Link
                            className={`${
                              context.location.pathname ===
                              "/dashboard/mycontacts"
                                ? "selected"
                                : ""
                            }`}
                            to={`/dashboard/mycontacts${window.location.search}`}
                          >
                            <span>Contacts</span>
                          </Link> */}

                          {/* <Popover
                        containerStyle={{
                          position: "relative",
                          right: 25
                        }}
                        isOpen={isAdminPopOverVisible}
                        position={["bottom", "right"]}
                        content={({ position, targetRect, popoverRect }) => (
                          <ArrowContainer
                            position={position}
                            targetRect={targetRect}
                            align="end"
                            popoverRect={popoverRect}
                            arrowColor="lightgrey"
                            arrowSize={7}
                            arrowStyle={{ opacity: 1 }}
                            containerStyle={{
                              right: 12
                            }}
                            arrow="center">
                            <PopoverStyled>
                                <Link
                                    to="/dashboard/attendance"
                                    onClick={() => {
                                      setIsAdminPopOverVisible(false);
                                    }}>
                                    <span>Attendance</span>
                                </Link>
                            </PopoverStyled>

                            <PopoverStyled>
                                <Link
                                    to="/dashboard/forms"
                                    onClick={() => {
                                      setIsAdminPopOverVisible(false);
                                    }}>
                                    <span>Forms</span>
                                </Link>
                            </PopoverStyled>
                          </ArrowContainer>
                        )}
                        onClickOutside={({ position, targetRect, popoverRect }) => {
                          setIsAdminPopOverVisible(false);
                        }}>
                          <a
                          onClick={() => {
                            console.log("pop over is visible")
                            setIsAdminPopOverVisible(true);
                          }}>
                             <span>Admin</span>
                          </a>
                      </Popover> */}

                          {/* <Link
                          className={`${
                            context.location.pathname ===
                            "/dashboard/builder"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/builder">
                          <span>Admin</span>
                        </Link> */}
                        </>
                      )
                    }
                    {currentUserType === "USER" && (
                      <>
                        {/* <a
                        href="#"
                        onClick={() => {
                          const currentParent = userApplications && userApplications[0] && userApplications
                          const parentIds = currentParent && currentParent.map(item => item.parents && item.parents[0] && item.parents[0].parent_id).join(',');
                          const groupIds = userApplications.map(item =>  {
                            const ids = item.class_teacher.split(',').filter(id => id && id !== '');
                            return ids;
                          }).flat().join(',');
                          window.location.href= `/dashboard/grades/input?appGroupIds=${groupIds}&parent_ids=${parentIds}&is_parent=true`
                        }}
                        className={`${
                          context.location.pathname.includes('dashboard/grades/input')
                            ? "selected"
                            : ""
                        }`}
                        to="/dashboard/bcdisplaycalendar">
                        <span>Data</span>
                      </a> */}
                        <Link
                          className={`${
                            context.location.pathname ===
                            "/dashboard/attendance/parents"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/attendance/parents"
                        >
                          <span>Home</span>
                        </Link>
                        {/* <Link
                          className={`${
                            context.location.pathname === "/dashboard/parent"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/parent"
                        >
                          <span>Home</span>
                        </Link> */}
                        <Link
                          className={`${
                            context.location.pathname ===
                            "/dashboard/attendance/child"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/attendance/child"
                        >
                          <span>Attendance</span>
                        </Link>
                        {/* <Link
                          className={`${
                            context.location.pathname ===
                            "/dashboard/parent/attendance"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/parent/attendance"
                        >
                          <span>Attendance</span>
                        </Link> */}
                        <Link
                          className={`${
                            context.location.pathname ===
                            "/dashboard/myapplication"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/myapplication"
                        >
                          <span>My Application</span>
                        </Link>
                        <Link
                          className={`${
                            context.location.pathname ===
                            "/dashboard/parentcontacts"
                              ? "selected"
                              : ""
                          }`}
                          to="/dashboard/parentcontacts"
                        >
                          <span>Contacts</span>
                        </Link>
                      </>
                    )}

                    {currentUserType === "VENDOR" && (
                      <Link
                        className={`${
                          context.location.pathname === "/dashboard/metrics"
                            ? "selected"
                            : ""
                        }`}
                        to={`/dashboard/metrics${window.location.search}`}
                      >
                        <span>Metrics</span>
                      </Link>
                    )}
                    <div id="dashboard-setting">
                      <Link
                        className={`${
                          context.location.pathname ===
                          "/dashboard/notifications"
                            ? "selected"
                            : ""
                        }`}
                        to="/dashboard/notifications"
                      >
                        {/* <FontAwesomeIcon icon={faBell} className="icon-item" /> */}
                        <img src={Bell} className="icon-item" />
                      </Link>
                      {/* <Link
                        className={`${
                          context.location.pathname === "/dashboard/settings"
                            ? "selected"
                            : ""
                        }`}
                        to="/dashboard/settings">
                        <FontAwesomeIcon icon={faCog} />
                      </Link> */}

                      <Popover
                        containerStyle={{
                          position: "relative",
                          right: 25,
                        }}
                        isOpen={isPopOverSettingsVisible}
                        position={["bottom", "right"]}
                        content={({ position, targetRect, popoverRect }) => (
                          <ArrowContainer
                            position={position}
                            targetRect={targetRect}
                            align="end"
                            popoverRect={popoverRect}
                            arrowColor="lightgrey"
                            arrowSize={7}
                            arrowStyle={{ opacity: 1 }}
                            containerStyle={{
                              right: 12,
                            }}
                            arrow="center"
                          >
                            <PopoverStyled>
                              {currentUserType === "VENDOR" && (
                                <Link
                                  to="/dashboard/admin"
                                  onClick={() => {
                                    setIsPopOverSettingsVisible(false);
                                  }}
                                >
                                  <span>Manage Admin</span>
                                </Link>
                              )}
                            </PopoverStyled>
                          </ArrowContainer>
                        )}
                        onClickOutside={({
                          position,
                          targetRect,
                          popoverRect,
                        }) => {
                          setIsPopOverSettingsVisible(false);
                        }}
                      >
                        <a
                          onClick={() => {
                            setIsPopOverSettingsVisible(true);
                          }}
                        >
                          {/* <FontAwesomeIcon icon={faCog} className="icon-item" /> */}
                          <img src={Settings} className="icon-item" />
                        </a>
                      </Popover>

                      <Popover
                        containerStyle={{
                          position: "relative",
                          right: 25,
                        }}
                        isOpen={isPopOverVisible}
                        position={["bottom", "right"]}
                        content={({ position, targetRect, popoverRect }) => (
                          <ArrowContainer
                            position={position}
                            targetRect={targetRect}
                            align="end"
                            popoverRect={popoverRect}
                            arrowColor="lightgrey"
                            arrowSize={7}
                            arrowStyle={{ opacity: 1 }}
                            containerStyle={{
                              right: 12,
                            }}
                            arrow="center"
                          >
                            <PopoverStyled>
                              <Link
                                to="/dashboard/myprofile"
                                onClick={() => {
                                  setIsPopOverVisible(false);
                                }}
                              >
                                {/* <FontAwesomeIcon icon={faUser} /> */}
                                <span>Profile</span>
                              </Link>
                              {/* {currentUserType === "VENDOR" && (
                                <Link
                                  to="/dashboard/myapplication"
                                  onClick={() => {
                                    setIsPopOverVisible(false);
                                  }}>
                                
                                  <span>Application</span>
                                </Link>
                              )} */}
                              {/* {currentUserType === "VENDOR" && (
                                <Link
                                  to="/dashboard/audittrail"
                                  onClick={() => {
                                    setIsPopOverVisible(false);
                                  }}>
                                  <span>Audit Trail</span>
                                </Link>
                              )} */}
                              <a
                                onClick={() => {
                                  dispatch(requestLogout());
                                  setIsPopOverVisible(false);
                                  navigate("/login", { replace: true });
                                }}
                              >
                                {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
                                <span>Logout</span>
                              </a>
                            </PopoverStyled>
                          </ArrowContainer>
                        )}
                        onClickOutside={() => {
                          setIsPopOverVisible(false);
                        }}
                      >
                        <a
                          onClick={() => {
                            setIsPopOverVisible(true);
                          }}
                        >
                          <img
                            src={`${currentUserProfilePhoto}?t=${new Date().getTime()}`}
                            className="profile"
                          />
                        </a>
                      </Popover>
                    </div>
                  </>
                );
              }
            }}
          />
        </div>
      </HeaderStyled>
      {/* Mobile Menu */}
      <div className={`${isMenuOpen ? "show" : "hide"}`}>
        <MobileMenu>
          <div id="app-header-left-mb">
            {location.href.includes(applicationUrl) ||
            location.href.includes(customFormUrl) ? (
              auth.status == "SIGNED_IN" ? (
                ""
              ) : (
                // <div className="header-login"
                //   onClick={() => {setShowLoginBox(true)}}
                // >
                //   Login
                // </div>
                <div />
              )
            ) : (
              ""
            )}
            <Location
              children={(context) => {
                if (
                  context.location.pathname === "/auth/forgot-password" ||
                  context.location.pathname === "/auth/create"
                ) {
                  return (
                    <>
                      <Link data-testid="app-header-login" to="/login">
                        Login
                      </Link>
                    </>
                  );
                }
              }}
            />
            <Location
              children={(context) => {
                if (
                  context.location.pathname.includes("dashboard") &&
                  auth.status === "SIGNED_IN"
                ) {
                  return (
                    <>
                      <div id="dashboard-setting" className="dash-setting-mb">
                        {/* User Profile */}
                        <Popover
                          containerStyle={{
                            position: "relative",
                            right: 25,
                          }}
                          isOpen={isPopOverVisibleMb}
                          position={["bottom", "right"]}
                          content={({ position, targetRect, popoverRect }) => (
                            <ArrowContainer
                              position={position}
                              targetRect={targetRect}
                              align="end"
                              popoverRect={popoverRect}
                              arrowColor="lightgrey"
                              arrowSize={7}
                              arrowStyle={{ opacity: 1 }}
                              containerStyle={{
                                right: 12,
                              }}
                              arrow="center"
                            >
                              <PopoverStyled>
                                <Link
                                  to="/dashboard/myprofile"
                                  onClick={() => {
                                    setIsPopOverVisibleMb(false);
                                  }}
                                >
                                  {/* <FontAwesomeIcon icon={faUser} /> */}
                                  <span>Profile</span>
                                </Link>
                                <a
                                  onClick={() => {
                                    dispatch(requestLogout());
                                    setIsPopOverVisibleMb(false);
                                    navigate("/login", { replace: true });
                                  }}
                                >
                                  {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
                                  <span>Logout</span>
                                </a>
                              </PopoverStyled>
                            </ArrowContainer>
                          )}
                          onClickOutside={() => {
                            setIsPopOverVisibleMb(false);
                          }}
                        >
                          <a
                            onClick={() => {
                              setIsPopOverVisibleMb(true);
                            }}
                          >
                            <img
                              src={`${currentUserProfilePhoto}?t=${new Date().getTime()}`}
                              className="profile"
                            />
                          </a>
                        </Popover>
                        {/* Settings */}
                        <div className="setting-mb">
                          <Link
                            className={`${
                              context.location.pathname ===
                              "/dashboard/notifications"
                                ? "selected"
                                : ""
                            }`}
                            to="/dashboard/notifications"
                          >
                            {/* <FontAwesomeIcon icon={faBell} className="icon-item" /> */}
                            <img src={Bell} className="icon-item" />
                          </Link>
                          <Popover
                            containerStyle={{
                              position: "relative",
                              right: 25,
                            }}
                            isOpen={isPopOverSettingsVisibleMb}
                            position={["bottom", "right"]}
                            content={({
                              position,
                              targetRect,
                              popoverRect,
                            }) => (
                              <ArrowContainer
                                position={position}
                                targetRect={targetRect}
                                align="end"
                                popoverRect={popoverRect}
                                arrowColor="lightgrey"
                                arrowSize={7}
                                arrowStyle={{ opacity: 1 }}
                                containerStyle={{
                                  right: 12,
                                }}
                                arrow="center"
                              >
                                <PopoverStyled>
                                  {currentUserType === "VENDOR" && (
                                    <Link
                                      to="/dashboard/admin"
                                      onClick={() => {
                                        setIsPopOverSettingsVisibleMb(false);
                                      }}
                                    >
                                      <span>Manage Admin</span>
                                    </Link>
                                  )}
                                </PopoverStyled>
                              </ArrowContainer>
                            )}
                            onClickOutside={({
                              position,
                              targetRect,
                              popoverRect,
                            }) => {
                              setIsPopOverSettingsVisibleMb(false);
                            }}
                          >
                            <a
                              onClick={() => {
                                setIsPopOverSettingsVisibleMb(true);
                              }}
                            >
                              {/* <FontAwesomeIcon icon={faCog} className="icon-item" /> */}
                              <img src={Settings} className="icon-item" />
                            </a>
                          </Popover>
                        </div>
                      </div>
                      {currentUserType === "VENDOR" && (
                        <>
                          <Link
                            className={`${
                              context.location.pathname ===
                              "/dashboard/application"
                                ? "selected"
                                : ""
                            }`}
                            to={`/dashboard/application${window.location.search}`}
                          >
                            <span>Application</span>
                          </Link>
                          <Link
                            className={`${
                              context.location.pathname ===
                              "/dashboard/newgroups"
                                ? "selected"
                                : ""
                            }`}
                            to={`/dashboard/newgroups${window.location.search}`}
                          >
                            <span>Groups</span>
                          </Link>
                        </>
                      )}
                      {
                        // Temporary Fix
                        // Trello card https://trello.com/c/BXEQaXbB/252-for-users-only-can-we-only-show-the-application-page-and-menu-hide-dashboard-calendar-events-and-contacts-pages-and-menu-items
                        currentUserType === "VENDOR" && (
                          <>
                            <Popover
                              containerStyle={{
                                position: "relative",
                                right: 25,
                              }}
                              isOpen={isPopOverDataVisibleMb}
                              position={["bottom", "right"]}
                              content={({
                                position,
                                targetRect,
                                popoverRect,
                              }) => (
                                <ArrowContainer
                                  position={position}
                                  targetRect={targetRect}
                                  align="end"
                                  popoverRect={popoverRect}
                                  arrowColor="lightgrey"
                                  arrowSize={7}
                                  arrowStyle={{ opacity: 1 }}
                                  containerStyle={{
                                    right: 12,
                                  }}
                                  arrow="center"
                                >
                                  <PopoverStyled>
                                    <Link
                                      to={`/dashboard/studentdata${window.location.search}`}
                                      onClick={() => {
                                        setIsPopOverDataVisibleMb(false);
                                      }}
                                    >
                                      <span>Attendance</span>
                                    </Link>
                                    <Link
                                      to="/dashboard/attendance/events"
                                      onClick={() => {
                                        setIsPopOverDataVisibleMb(false);
                                      }}
                                    >
                                      <span>Existing Events</span>
                                    </Link>
                                    <Link
                                      to="/dashboard/newcalendar"
                                      onClick={() => {
                                        setIsPopOverDataVisibleMb(false);
                                      }}
                                    >
                                      <span>New Calendar</span>
                                    </Link>
                                  </PopoverStyled>
                                </ArrowContainer>
                              )}
                              onClickOutside={({
                                position,
                                targetRect,
                                popoverRect,
                              }) => {
                                setIsPopOverDataVisibleMb(false);
                              }}
                            >
                              <a
                                onClick={() => {
                                  setIsPopOverDataVisibleMb(true);
                                }}
                              >
                                {/* <FontAwesomeIcon icon={faCog} className="icon-item" /> */}
                                <span>
                                  Data{" "}
                                  <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className="icon-item icon-dropdown"
                                  />
                                </span>
                              </a>
                            </Popover>
                            <Link
                              className={`${
                                context.location.pathname ===
                                "/dashboard/grades"
                                  ? "selected"
                                  : ""
                              }`}
                              to={`/dashboard/grades${window.location.search}`}
                            >
                              <span>Grades</span>
                            </Link>
                          </>
                        )
                      }
                      {currentUserType === "USER" && (
                        <>
                          <Link
                            className={`${
                              context.location.pathname ===
                              "/dashboard/myapplication"
                                ? "selected"
                                : ""
                            }`}
                            to="/dashboard/myapplication"
                          >
                            <span>My Application</span>
                          </Link>
                          <Link
                            className={`${
                              context.location.pathname ===
                              "/dashboard/parentcontacts"
                                ? "selected"
                                : ""
                            }`}
                            to="/dashboard/parentcontacts"
                          >
                            <span>Contacts</span>
                          </Link>
                        </>
                      )}

                      {currentUserType === "VENDOR" && (
                        <Link
                          className={`${
                            context.location.pathname === "/dashboard/metrics"
                              ? "selected"
                              : ""
                          }`}
                          to={`/dashboard/metrics${window.location.search}`}
                        >
                          <span>Metrics</span>
                        </Link>
                      )}
                    </>
                  );
                }
              }}
            />
          </div>
        </MobileMenu>
      </div>
      {children}
      <footer data-testid="app-footer"></footer>
      {/* {location.href.includes(applicationUrl) || location.href.includes(customFormUrl) ?
          auth.status == "SIGNED_IN" ? ""
            : showLoginBox ? (
              <LoginBox
                handleClose={handleLoginBoxClose}
              >
              </LoginBox>
            )
              : ""
          : ""
        } */}
    </LayoutStyled>
  );
}
